import { currentProjectName } from '@/config';
import { GetStaticProps } from 'next';
import { dynamicErrorPageTemplates } from '../dynamicErrorPageTemplates';

type ErrorPageProps = {
  projectName: keyof typeof dynamicErrorPageTemplates;
};

const ErrorPage = ({ projectName }: ErrorPageProps) => {
  const Page = dynamicErrorPageTemplates[projectName];

  return <Page />;
};

export const getStaticProps: GetStaticProps = () => {
  return {
    props: {
      projectName: currentProjectName,
    },
  };
};

export default ErrorPage;
